import React from 'react';

import { Meta } from '../components/Meta';
import { Main } from '../components/Main';

const title = 'Stránka nebola nájdená';

export default () => (
  <Main>
    <Meta title={title} />
    <h1>{title}</h1>
    <p>Pravdepodobne ste zadali zlú adresu.</p>
  </Main>
);
